<template id="search">
  <div class="container">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <h1>Onthaal</h1>
      <form m v-on:submit.prevent="search">
        <div class="row">
          <div class="col-8 big-search">
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <div class="input-group-text">{{ prefix }}</div>
              </div>
              <input type="text" class="form-control" ref="search" v-model="mvmnummer" :disabled="searching" v-focus />
            </div>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-lg btn-success" v-on:click="search()" :disabled="searching">
              <i class="fas fa-search"></i><span class="d-none d-md-inline"> Zoeken</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="prefix">Type</label>
              </div>
              <select class="form-select" id="prefix" v-model="prefix">
                <option value="MVM">Leden</option>
                <option value>Op naam zoeken</option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div class="container" v-if="results.length > 0">
        <div class="row" v-for="result in results" v-bind:key="result.id">
          <div class="col-12 col-md-1 text-center">
            <i class="fas fa-exclamation-triangle fa-3x" style="color: #e0cf50" v-if="result.controle"></i>
            <i class="fas fa-check-square fa-3x" style="color: green" v-else-if="getStatus(result) == 'Actief'"></i>
            <i class="far fa-history fa-3x" style="color: #e0cf50" v-if="getStatus(result) == 'Te Verlengen'"></i>
            <i class="fas fa-times-octagon fa-3x" style="color: red" v-if="getStatus(result) == 'Niet Actief'"></i>
          </div>

          <div class="col-12 col-md-11">
            <div class="row">
              <div class="col-md-1 col-4">
                <div class="col-title">Nummer</div>
                MVM{{ result.mvmnummer }}
              </div>
              <div class="col-md-1 col-4">
                <div class="col-title">Voornaam</div>
                {{ result.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}
              </div>
              <div class="col-md-2 col-4">
                <div class="col-title">Naam</div>
                {{ result.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
              </div>
              <div class="col-md-1 col-4">
                <div class="col-title">Dag</div>
                {{ result.dag }}
              </div>
              <div class="col-md-2 col-4">
                <div class="col-title">Einddatum</div>
                {{
      getEindDatum(result).toLocaleDateString("nl-BE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    }}
              </div>
              <div class="col-md-1 col-4">
                <div class="col-title">Status</div>
                {{ getStatus(result) }}
              </div>
              <div class="col-md-4 col-12">
                <div class="col-title">Print</div>
                <form class="row">
                  <div class="col-6 m-1">
                    <select class="form-select" v-model="printType">
                      <option>Gewoon</option>
                      <option>Voorrang</option>
                      <option>Leveren bij inschrijving</option>
                      <option>Andere</option>
                    </select>
                  </div>
                  <div class="col-12 m-1" v-if="printType == 'Andere'">
                    <input class="form-control" v-model="customPrintText" ref="customPrintText" id="customPrintText" />
                  </div>
                  <div class="btn-group col-5 m-1 p-1 row">
                    <button type="button" class="btn btn-primary print-num col-10" v-on:click="print(result)"
                      v-bind:disabled="printing"><i class="fad fa-print"></i></button>
                    <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split col-2"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-bind:disabled="printing">
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#" v-on:click="print(result, 'voeding')"><i
                            class="far fa-print"></i> Enkel Voeding</a></li>
                      <li><a class="dropdown-item" href="#" v-on:click="print(result, 'markt')"><i
                            class="far fa-print"></i> Enkel Markt</a></li>
                    </ul>
                  </div>
                  <div class="col-1">
                    <i class="far fa-check-square fa-3x" style="color: green" v-if="hasPrinted(result.mvmnummer)"></i>
                  </div>
                </form>
              </div>
              <div class="col-md-3 col-4" v-if="result.redenControle != ''">
                <div class="col-title">Reden Controle</div>
                {{ result.redenControle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ledenService } from "../../_services/leden.service";
import { onthaalService } from "../../_services/onthaal.service";
import * as voedingHelper from "../../_helpers/voeding";
import { keyboardHelper } from "@/_helpers/keyboard.helper";
import { authService } from "@/_services/auth.service";
import { voedingPrint, marktPrint } from "../../_helpers/printer";
import { getExactAge } from "../../_helpers/dates";
import { getStatus, getEindDatum } from "../../_helpers/leden";

export default {
  template: "#search",
  components: {},
  data: function () {
    return {
      getStatus,
      getEindDatum,
      printed: {},
      printType: "Gewoon",
      loading: false,
      mvmnummer: "",
      prefix: "MVM",
      searching: false,
      printing: false,
      ticketCount: 1,
      results: [],
      customPrintText: "",
      dxuAbort: null,
    };
  },

  mounted() {
    // Attach keydown event listener to window when component mounts
    window.addEventListener('keydown', this.handleKeyPress);
    this.handleDXU();
  },
  beforeDestroy() {
    // Remove keydown event listener before the component is destroyed
    window.removeEventListener('keydown', this.handleKeyPress);
    if (this.dxuAbort) {
      this.dxuAbort.abort();
    }
  },

  methods: {
    handleDXU: async function () {
      const dxuResponse = await fetch("http://127.0.0.1:8080");
      if ((await dxuResponse.text()).indexOf("MEMOR") === -1) {
        return
      }

      //unfocus search input
      this.$refs.search.blur();

      this.dxuAbort = new AbortController();

      // keep fetchhing "/scan?action=read_listener" until aborted
      for (; ;) {
        try {
          const scan = await fetch("http://127.0.0.1:8080/scan?action=read_listener", { signal: this.dxuAbort.signal });
          const dxuText = await scan.text();

          if (dxuText.length > 0) {
            this.mvmnummer = dxuText;
            this.search();
          }
          if (this.dxuAbort.signal.aborted) {
            break;
          }
        } catch (e) {
          console.log(e);
          await new Promise(resolve => setTimeout(resolve, 200));
        }
      }
    },
    handleKeyPress: function (event) {
      // check if the search input is focused
      if (document.activeElement === this.$refs.search) {
        return;
      }
      // do not do this if customPrintText is focussed
      if (document.activeElement == this.$refs.customPrintText[0]) {
        return;
      }
      event.preventDefault();
      if (event.key.length === 1 || parseInt(event.key, 10) >= 0) {
        this.mvmnummer += event.key;
      }
      // handle backspace
      if (event.key === "Backspace") {
        this.mvmnummer = this.mvmnummer.slice(0, -1);
      }
      // handle enter
      if (event.key === "Enter") {
        this.search();
      }

    },
    hasPrinted: function (number) {
      const today = new Date().toLocaleDateString();

      if (!this.printed[today]) {
        return false;
      }

      return (this.printed[new Date().toLocaleDateString()] || []).includes(
        number
      );
    },
    print: async function (result, selector) {
      let loader = this.$loading.show({
                    canCancel: false,
                    color: "#5069b0",
                });
      try {
        this.printing = true;

        let printVoeding = true;
        let printMarkt = true;

        if (selector === "markt") {
          printVoeding = false
        } else if (selector === "voeding") {
          printMarkt = false
        }

        if (printMarkt) {
          const marktResult = await onthaalService.lookUpMarkt(
            result.mvmnummer
          );

          if (marktResult.length > 0) {
            for (let markt of marktResult) {
              await marktPrint(markt);
            }
          }
        }


        if (printVoeding) {

          const voedingResult = await onthaalService.lookUpVoeding(
            result.mvmnummer
          );

          const printData = {
            ticketCount: this.ticketCount,
            doelgroepnummer: `MVM${result.mvmnummer}`,
            typeVoeding: result.gezin.typeVoeding,
            specialeVoeding: voedingResult.specialeVoeding,
            opmerking: voedingResult.opmerking,
            voornaam: result.gezin.leden.find((l) => l.gezinsHoofd).voornaam,
            naam: result.gezin.leden.find((l) => l.gezinsHoofd).achternaam,
            printType: this.printType,
            needsMelkpoeder: voedingHelper.needsMelkpoeder(voedingResult),
            needsVerjaardag: voedingHelper.needsVerjaardag(result),
            volwassenen: result.gezin.leden.filter(
              (l) =>
                getExactAge(l.geboortedatum) >= 12 ||
                getExactAge(l.geboortedatum) == -1 // no age probably means adult
            ).length,
            kinderen: result.gezin.leden.filter(
              (l) =>
                getExactAge(l.geboortedatum) < 12 &&
                getExactAge(l.geboortedatum) >= 0
            ).length,
          };

          if (printData.printType == "Andere") {
            printData.printType = this.customPrintText;
          }

          await voedingPrint(printData);

        }

        loader.hide();
        this.$Simplert.open({
          title: "Print verstuurd",
          message: "Print opdracht verstuurd",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            this.$refs.search.focus();
          },
        });

        const today = new Date().toLocaleDateString();
        if (!this.printed[today]) {
          this.$set(this.printed, today, []);
        }
        this.$set(
          this.printed,
          today,
          this.printed[today].concat([result.mvmnummer])
        );
        this.ticketCount++;
      } catch (e) {
        loader.hide();
        this.$Simplert.open({
          title: "Error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            this.$refs.search.focus();
          },
        });
      }

      // wait 3 seconds before enabling the button again to prevent accidental double clicks
      setTimeout(() => {
        this.printing = false;
      }, 3000);
    },
    search: async function () {
      this.searching = true;
      try {
        this.mvmnummer = keyboardHelper.superCrazyAzertyBarcodeFix(
          this.mvmnummer
        );

        // reset printType
        this.printType = "Gewoon";
        this.customPrintText = "";

        let searchPrefix = this.prefix;
        let seachTerm = this.mvmnummer;
        let isRRN = false;

        if (
          this.mvmnummer.length >= 11 &&
          !isNaN(parseInt(this.mvmnummer, 10))
        ) {
          // we have a rijksregisternummer!
          seachTerm = this.mvmnummer.substring(0, 11);
          searchPrefix = "";
          isRRN = true;
        }

        this.results = [];

        if (searchPrefix == "MVM") {
          const result = await ledenService.get(seachTerm);

          this.results.push(result);

          if (result.voorrang) {
            this.printType = "Voorrang";
          }

          this.mvmnummer = "";
        } else {
          if (isRRN) {
            this.results = (
              await ledenService.search('', seachTerm) // use RRN search
            ).data;
          } else {
            this.results = (
              await ledenService.search(`${searchPrefix}${seachTerm}`)
            ).data;
          }

          console.log(this.results);

          if (isRRN && this.results.length > 0) {
            this.mvmnummer = "";
          }
        }
      } catch (e) {
        this.$Simplert.open({
          title: "Geen Resultaten!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }

      this.searching = false;
    },
  },

  created: function () {
    authService.check().catch(() => this.$router.push("/login"));
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
