<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mb-2 mt-2">
          <span class="float-start">
            <button type="button" class="btn btn-light btn-lg" :disabled="saving" v-on:click="goBack()">
              <i class="fad fa-long-arrow-left" />Terug
            </button>
          </span>
          <span class="float-end">
            <button type="button" class="btn btn-info btn-lg" v-on:click="print()">
              <i class="far fa-print" /> Print
            </button>
            &nbsp;
            <button type="button" class="btn btn-success btn-lg" v-on:click="save()" :disabled="saving || !hasChanges">
              <i class="far fa-save" /> Opslaan
            </button>
          </span>
        </div>
      </div>
      <h3>Info</h3>
      <div class="row mb-3 materiaal-info" v-if="getStatus(klant) == 'Niet Actief'">
        <div class="col-3 mb-3">
          <h5>Status</h5>
          <p class="info" style="color: red">Niet Actief</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info" v-if="klant.nietBetalen">
        <div class="col-3 mb-3">
          <h5>Info</h5>
          <p class="info" style="color: red">Niet Betalen</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info">
        <div class="col-5 mb-3">
          <h5>Naam</h5>
          <p class="info">
            {{ klant.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}
            {{ klant.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
          </p>
        </div>
        <div class="col-4 mb-3">
          <h5>MVM Nummer</h5>
          <p class="info">MVM{{ klant.mvmnummer }}</p>
        </div>
        <div class="col-3 mb-3">
          <h5>Woonplaats</h5>
          <p class="info">
            {{ klant.gezin.postcode }} {{ klant.gezin.gemeente }}
          </p>
        </div>
        <div class="col-8">
          <h5>Gezinsleden</h5>
          <pre>{{ klant.huishouden }}</pre>
        </div>
        <div class="col-4">
          <h5>Opmerking</h5>
          <textarea class="form-control" v-model="opmerking" rows="2"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mx-auto">
          <div id="accordion" class="accordion">
            <div v-for="materiaalType in materiaalTypes" v-bind:key="idSafeName(materiaalType)">
              <div class="accordion-item">
                <div v-bind:id="idSafeName(materiaalType) + 'heading'"
                  class="accordion-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 font-weight-bold">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      v-bind:data-bs-target="'#' + idSafeName(materiaalType)" aria-expanded="false"
                      v-bind:aria-controls="idSafeName(materiaalType)">
                      {{ materiaalType.naam }}
                    </button>
                  </h6>
                </div>
                <div v-bind:id="idSafeName(materiaalType)" v-bind:aria-labelledby="idSafeName(materiaalType)"
                  data-bs-parent="#accordion" class="collapse">
                  <div class="card-body">
                    <div class="row">
                      <h3 class="col-12">
                        <span class="float-end">
                          <button type="button" class="btn btn-info me-2" :disabled="saving" v-on:click="showFilter ? resetFilter() : openFilter()">
                            <i class="fas fa-filter" />
                          </button>
                          <button type="button" class="btn btn-success" :disabled="saving" v-on:click="addRow(materiaalType)">
                            <i class="fas fa-plus-square" /> Rij Toevoegen
                          </button>
                        </span>
                      </h3>
                    </div>
                    <table class="table">
                      <colgroup>
                        <col />
                        <col />
                        <col v-if="materiaalType.perPersoon" />
                        <col />
                        <col />
                        <col v-if="materiaalType.opMaat" />
                        <col />
                      </colgroup>
                      <thead class="table-dark">
                        <tr>
                          <th>Print</th>
                          <th>
                            Datum
                            <div v-if="showFilter">
                              <datepicker :format="'yyyy-MM-dd'" v-model="filter.datum" :language="nl"></datepicker>
                            </div>
                          </th>
                          <th>Aantal</th>
                          <th v-if="materiaalType.perPersoon">
                            Kind
                            <div v-if="showFilter">
                              <multiselect v-model="filter.ontvanger" :options="getKinderen()" placeholder="Filter"
                                @select="(e) => setFilter(materiaalType.ID, 'ontvanger', e)"
                                @remove="() => setFilter(materiaalType.ID, 'ontvanger', {})" selectLabel=""></multiselect>
                            </div>
                          </th>
                          <th>
                            Item
                            <div v-if="showFilter">
                              <multiselect v-model="filter.object" track-by="ID" label="naam"
                                @select="(e) => setFilter(materiaalType.ID, 'object', e)"
                                @remove="() => setFilter(materiaalType.ID, 'object', {})" :options="materiaalType.opties"
                                placeholder="Filter" :allow-empty="true" selectLabel="">
                              </multiselect>
                            </div>
                          </th>
                          <th v-if="materiaalType.opMaat">
                            Maat
                            <div v-if="showFilter">
                              <multiselect v-model="filter.maat" track-by="naam" label="naam"
                                :options="(filter.object || []).maten || []" placeholder="Filter"
                                @select="(e) => setFilter(materiaalType.ID, 'maat', e)"
                                @remove="() => setFilter(materiaalType.ID, 'maat', {})" selectLabel=""></multiselect>
                            </div>
                          </th>
                          <th>Opmerking</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in materiaalVoorCategorie(
                            materiaalType
                          )" v-bind:key="item.ID || item._intID">
                          <td style="width: 50px">
                            <input type="checkbox" v-model="item.print" />
                          </td>
                          <td style="width: 160px">
                            <datepicker :format="'yyyy-MM-dd'" v-model="item.datum" :language="nl"></datepicker>
                          </td>
                          <td style="width: 70px">
                            <input v-model.number="item.aantal" class="form-control" type="number" min="1"
                              style="width: 60px" />
                          </td>
                          <td v-if="materiaalType.perPersoon">
                            <multiselect v-model="item.ontvanger" :options="getKinderen()" placeholder="Selecteer een"
                              selectLabel=""></multiselect>
                          </td>
                          <td>
                            <multiselect v-model="item.object" track-by="naam" label="naam"
                              :options="materiaalType.opties" placeholder="Selecteer een"
                              @select="selectedNewItem($event, item)" :allow-empty="false" deselect-label=""
                              selectLabel=""></multiselect>
                          </td>
                          <td v-if="materiaalType.opMaat">
                            <multiselect v-model="item.maat" track-by="naam" label="naam"
                              :options="(item.object || []).maten || []" placeholder="Selecteer een"
                              @remove="item.maat = null" :allow-empty="false" deselect-label="" selectLabel="">
                            </multiselect>
                          </td>

                          <td>
                            <input v-model="item.opmerking" class="form-control" placeholder="Opmerking"
                              style="width: 170px" />
                          </td>
                          <td style="width: 50px">
                            <button type="button" class="btn btn-outline-danger"
                              v-on:click="removeRow(materiaalType, item)">
                              <i class="fad fa-trash" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <paginate :page-count="pageCount[materiaalType.ID]" :page-range="3" :margin-pages="0"
                      containerClass="pagination" page-class="page-item" page-link-class="page-link" prev-text="Vorige"
                      prev-class="page-item" prev-link-class="page-link" next-text="Volgende" next-class="page-item"
                      next-link-class="page-link" :click-handler="(e) => pageClickCallback(e, materiaalType.ID)">
                    </paginate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { materiaalService } from "../../_services/materiaal.service";
import { materiaalPrint } from "../../_helpers/printer";
import { ledenService } from "../../_services/leden.service";
import { getStatus } from "../../_helpers/leden";
import * as moment from "moment";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";

import Datepicker from "@maartje/vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  props: ["id"],
  components: {
    Datepicker,
    Multiselect,
  },
  computed: {
    hasChanges: function () {
      if (this.hasHadChanges) {
        return true;
      }

      if (this.deleted.length > 0) {
        return true;
      }

      if (!this.originalData) {
        return false;
      }

      const old = JSON.parse(this.originalData);
      if (old.opmerking !== this.opmerking) {
        return true;
      }

      for (let catID in this.gekregen) {
        if (!this.gekregen[catID]) {
          continue;
        }

        const gekregenCopy = JSON.parse(
          JSON.stringify(this.gekregen[catID], getCircularReplacer())
        );
        // remove all .print properties as these are not in the database
        for (let gekregen of gekregenCopy) {
          delete gekregen.print;
        }

        if (
          JSON.stringify(gekregenCopy, getCircularReplacer()) !==
          this.originalGekregen[catID]
        ) {
          return true;
        }
      }

      return false;
    },
  },
  data: function () {
    return {
      getStatus,
      nl,
      filter: {
        object: {},
      },
      showFilter: false,
      klant: {},
      loading: true,
      originalData: "", //JSON stored here
      saving: false,
      hasHadChanges: false, // shortcut to set to changes once happened to save cpu
      gekregen: {}, // {catID: [items]}
      originalGekregen: {},
      opmerking: "",
      materiaalTypes: {},
      newItemCount: 0,
      categoryForID: {},
      deleted: [],
      pageCount: {}, // {catID: pageCount}
      currentPage: {}, // {catID: currentPage}
    };
  },
  methods: {
    validate: function () {
      for (let catID in this.gekregen) {
        if (!this.gekregen[catID]) {
          continue;
        }

        const gekrevenForCat = this.gekregen[catID];

        for (let gekregen of gekrevenForCat) {
          if (!gekregen.object || gekregen.object.ID <= 0) {
            throw new Error("item niet ingevuld");
          }

          if (
            gekregen.object.categorie.opMaat &&
            (!gekregen.maat || !gekregen.maat.ID)
          ) {
            throw new Error("maat niet ingevuld");
          }

          if (gekregen.object.categorie.perPersoon && !gekregen.ontvanger) {
            throw new Error("ontvanger niet ingevuld");
          }
          if (gekregen.maat) {
            gekregen.MaatID = gekregen.maat.ID;
          }
          gekregen.objectId = gekregen.object.ID;
          gekregen.datum = moment(new Date(gekregen.datum)).format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ); // damn you Go
        }
      }
    },
    idSafeName: function (cat) {
      return cat.naam.replace(" ", "");
    },
    materiaalVoorCategorie: function (cat) {
      return this.gekregen[cat.ID];
    },
    getKinderen: function () {
      const out = [];
      for (let lid of this.klant.gezin.leden) {
        out.push(`${lid.voornaam} ${lid.achternaam}`);
      }
      return out;
    },
    setFilter: function (catID, key, value) {
      if (key == "object") {
        this.filter.maat = {};
      }
      this.filter[key] = value;
      this.loadPage(catID, 1);
    },
    selectedNewItem: function (selectedOption, item) {
      this.hasHadChanges = true;
      item.maat = null;
      if (
        selectedOption &&
        selectedOption.maten &&
        selectedOption.maten.length == 1
      ) {
        item.maat = selectedOption.maten[0];
      }
    },
    addRow: async function (cat) {
      this.hasHadChanges = true;
      if (this.showFilter) await this.resetFilter();
      if (this.currentPage[cat.ID] != 1) {
        await this.loadPage(cat.ID, 1)
      }

      const gekregen = this.gekregen;
      gekregen[cat.ID] = [
        {
          aantal: 1,
          ontvanger: "",
          naam: "",
          maat: "",
          opmerking: "",
          object: { ID: 0, naam: "", categorie: cat },
          datum: new Date(),
          print: true,
          _intID: -1 * this.newItemCount, // as not to conflct with real IDs
        },
      ].concat(this.gekregen[cat.ID]);

      this.gekregen = gekregen;
      this.newItemCount++;
    },
    removeRow: function (cat, obj) {
      if (obj.ID > 0) {
        this.deleted.push(obj.ID);
      }
      this.hasHadChanges = true;
      this.gekregen[cat.ID] = this.gekregen[cat.ID].filter((aObj) => aObj != obj);
    },
    getGeslacht: function (naam) {
      // why is this function here...
      // well turns out I spent so much designing the database
      // without for one second even thinking about gender...
      for (let lid of this.klant.gezin.leden) {
        if (`${lid.voornaam} ${lid.achternaam}` == naam) {
          return lid.geslacht;
        }
      }
      return "onbekend";
    },
    getLeeftijd: function (naam) {
      // calculates age in a very inaccurate way as it is only used for birthday gifts
      for (let lid of this.klant.gezin.leden) {
        if (`${lid.voornaam} ${lid.achternaam}` == naam && lid.geboortedatum) {
          return (
            new Date().getFullYear() - new Date(lid.geboortedatum).getFullYear()
          );
        }
      }
      return "onbekend";
    },
    print: async function () {
      try {
        this.validate();
        await this.save();

        let items = [];

        for (let catID in this.gekregen) {
          if (!this.gekregen[catID]) {
            continue;
          }

          const gekregenForCat = this.gekregen[catID];
          for (let gekregen of gekregenForCat) {
            if (gekregen.print) {
              const item = {
                object: gekregen.object.naam,
                opmerking: gekregen.opmerking,
                maat: gekregen.maat ? gekregen.maat.naam : null,
                ontvanger: {},
                prijs: gekregen.object.prijs,
                extraEscposData: gekregen.object.extraEscposData,
              };
              if (gekregen.ontvanger) {
                item.ontvanger.naam = gekregen.ontvanger;
                item.ontvanger.geslacht = this.getGeslacht(gekregen.ontvanger);
              }

              if (
                this.categoryForID[gekregen.object.categorie.ID] &&
                this.categoryForID[gekregen.object.categorie.ID].printOpties
                  .printKindInfo
              ) {
                item.ontvanger.leeftijd = this.getLeeftijd(gekregen.ontvanger);
              }

              items.push(item);
            }
          }
        }

        if (items.length < 1) {
          throw new Error("Niets om te printen");
        }

        await materiaalPrint({
          klant: {
            mvmNummer: `MVM${this.klant.mvmnummer}`,
            voornaam: this.klant.gezin.leden.find((l) => l.gezinsHoofd)
              .voornaam,
            naam: this.klant.gezin.leden.find((l) => l.gezinsHoofd).achternaam,
          },
          items,
        });
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij printen!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
    },
    save: async function () {
      let loader = this.$loading.show({
                    canCancel: false,
                    color: "#5069b0",
                });
      try {
        this.saving = true;
        this.validate();
        const resp = await materiaalService.saveForNumber(
          this.klant.mvmnummer,
          { opmerking: this.opmerking }
        );

        let print = {}; // {catID: [items]}

        for (let catID in this.gekregen) {
          if (!this.gekregen[catID]) {
            continue;
          }

          const gekregenForCat = this.gekregen[catID];
          for (let i in gekregenForCat) {
            const resp = await materiaalService.saveRow(this.id, this.gekregen[catID][i]);
            if (resp.status !== "ok") {
              throw new Error(resp.message);
            }

            this.gekregen[catID][i].ID = resp.data.ID;

            if (this.gekregen[catID][i].print) {
              if (!print[catID]) {
                print[catID] = [];
              }
              print[catID].push(this.gekregen[catID][i]);
            }
          }
        }

        for (let id of this.deleted) {
          const resp = await materiaalService.deleteRow(this.id, 0, id);
          if (resp.status !== "ok") {
            throw new Error(resp.message);
          }
        }

        this.deleted = [];

        await this.loadPage(-1, -1);

        // re-set the print data
        for (let catID in print) {
          for (let printItem of print[catID]) {
            let found = false;
            for (let i in this.gekregen[catID]) {
              if (this.gekregen[catID][i].ID == printItem.ID) {
                this.gekregen[catID][i].print = true;
                found = true;
                break;
              }
            }
            if (!found) {
              // in case it fell off in pagination we can re-add it so it will be printed
              this.gekregen[catID].push(printItem);
            }
          }
        }

        this.saving = false;
        loader.hide();
        this.$Simplert.open({
          title: "Opgeslagen!",
          message: resp.message,
          type: "success",
          customCloseBtnText: "Sluiten",
        });
        this.hasHadChanges = false;
        this.originalData = JSON.stringify(
          { gekregen: this.gekregen, opmerking: this.opmerking },
          getCircularReplacer()
        );
        
        return true;
      } catch (e) {
        loader.hide();
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        this.saving = false;
        return false;
      }
    },
    pageClickCallback: async function (num, catID) {
      const action = () => this.loadPage(catID, num);
      if (this.hasChanges) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: () => {
            this.save().then((ok) => {
              if (ok) {
                action();
              }
            });
          },
          onClose: () => {
            this.hasHadChanges = false;
            action();
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Verder zonder opslagen",
        });
      } else {
        action();
      }
    },
    loadPage: async function (catID, num) {
      let cats = [catID];
      if (catID == -1) {
        cats = Object.keys(this.gekregen);
      }
      if (num < 1) {
        num = 1;
      }

      for (let catID of cats) {
        this.currentPage[catID] = num;
        const { data, totalEntries } = await materiaalService.lookPageUpNumberAndCategorie(
          this.id,
          catID,
          num,
          {
            object: (this.filter.object || {}).ID,
            ontvanger: this.filter.ontvanger,
            maat: (this.filter.maat || {}).ID,
          }
        );

        this.gekregen[catID] = data;
        this.originalGekregen[catID] = JSON.stringify(data);
        this.pageCount[catID] = Math.ceil(totalEntries / 10);
        if (this.pageCount[catID] < 1) {
          this.pageCount[catID] = 1;
        }
      }
    },
    showChangesWarning: function(onClose) {
      this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: () => {
            this.save().then((ok) => {
              if (ok) {
                onClose();
              }
            });
          },
          onClose: onClose,
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
    },
    goBack: function () {
      if (this.hasChanges) {
        this.showChangesWarning(() => {
            this.$router.push({ name: "materiaal-search" });
        })
      } else {
        this.$router.push({ name: "materiaal-search" });
      }
    },
    openFilter: function () {
      if (this.hasChanges) {
        this.showChangesWarning(() => {
          this.showFilter = true;
        })
      } else {
        this.showFilter = true;
      }
    },
    resetFilter: async function () { 
      this.filter = {
        ontvanger: "",
        maat: "",
        object: {}
      };

      await this.loadPage(-1, 1)
      this.showFilter = false;
    },
  },

  created: async function () {
    this.loading = true;
    this.resetFilter();

    let materiaalResponse;
    let ledenResponse;
    let materiaalOpties;

    try {
      ledenResponse = await ledenService.get(this.id);
      materiaalResponse = await materiaalService.lookUpNumber(this.id);
      materiaalOpties = await materiaalService.getObjectOptions();
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
    this.originalData = JSON.stringify({
      opmerking: materiaalResponse.opmerking,
    });

    // add option for composing view
    let keysToSort = [];
    let materiaalTypes = {};
    // TODO: adjust API calls with new structure to fetch per category instead of this hack
    for (let optie of materiaalOpties) {
      // add info to categoryForID for cat info lookup
      if (!this.categoryForID[optie.categorie.ID]) {
        this.categoryForID[optie.categorie.ID] = optie.categorie;

        await this.loadPage(optie.categorie.ID, 1);
      }
      if (!materiaalTypes[optie.categorie.naam]) {
        keysToSort.push(optie.categorie.naam);
        materiaalTypes[optie.categorie.naam] = optie.categorie;
        materiaalTypes[optie.categorie.naam].opties = [];
      }
      materiaalTypes[optie.categorie.naam].opties.push(optie);
    }

    // sort categories in view order
    keysToSort.sort(function (a, b) {
      return materiaalTypes[a].order - materiaalTypes[b].order;
    });

    for (let k of keysToSort) {
      this.materiaalTypes[k] = materiaalTypes[k];
    }

    this.klant = ledenResponse;
    this.opmerking = materiaalResponse.opmerking
      ? materiaalResponse.opmerking
      : "";

    this.huishoudenData = [];
    for (let lid of ledenResponse.gezin.leden) {
      let info = `${new Date(lid.geboortedatum).toLocaleDateString("sv")} ${lid.geslacht
        } - ${lid.voornaam} ${lid.achternaam}`;

      this.huishoudenData.push(info);
    }
    this.klant.huishouden = this.huishoudenData.sort().reverse().join("\n");

    this.loading = false;
  },
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
</script>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
<style>
ul {
  padding-left: 20px;
}

.multiselect__element {
  display: block;
  background-color: #e4f1e4;
}

.materiaal-info h5 {
  font-size: 1.1rem;
}

.info {
  font-weight: bolder;
  font-size: 1.4rem;
}

/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}
</style>
